.input-label {
  @apply
    text-sm
    font-medium
    pl-3;
}

.form-control{
  & .label {
    @apply pb-0;
  }
}

.input-wrapper {
  @apply relative w-full;
  & .input-error-message {
    @apply
      absolute
      z-10
      -bottom-5
      left-0
      ml-3
      text-xs
      font-medium
      leading-5
      text-error-color-500;
  }

  & .input-error-message-end {
    @apply
      absolute
      z-10
      -bottom-5
      right-0
      mr-3
      text-xs
      font-medium
      leading-5
      text-error-color-500;
  }
}

.input-icon {
  @apply absolute;

  &.left {
    @apply
      left-3
      top-[50%]
      translate-y-[-50%];
  }
  &.right {
    @apply
      right-3
      top-0
      bottom-0
      my-auto;
    }
}

input[type=text],
input[type=password],
input[type=email],
input[type=date],
input[type=number] {
  -moz-appearance   : textfield;
  -webkit-appearance: none;
  @apply
    w-full
    p-3
    h-[50px]
    rounded-full
    border-[1px]
    border-white-shades-400
    bg-white
    text-base
    font-medium;
    &:focus {
      @apply
        outline-none
        border-[1px]
        border-secondary-color-500;
    }
    &:disabled {
      @apply 
        bg-white-shades-200
        text-white-shades-400
        border-none;
    }
    &::placeholder { 
      @apply 
        text-white-shades-400
        text-base
        font-medium;
    }
  &.input-icon-left {
    @apply pl-10;
  }
  &.input-icon-right {
    @apply pr-10;
  }
}

select {
  &:invalid {
    @apply
      text-white-shades-400
      text-base
      font-medium;
  }

  & option {
    @apply
      text-secondary-color-500
      text-base
      font-medium;
  }

  & option[value=""], option:not([value]) {
    @apply
      text-white-shades-400
      text-base
      font-medium;
  }
}

.textarea {
  @apply
    border-[1px];
    
  --tw-bg-opacity: 0 !important;
  &:focus {
    outline-style: none!important;
    outline-width: 0px!important;
    outline-offset: 0px!important;
    @apply border-[1px] border-secondary-color-500;
  }
}

.select {
  --tw-bg-opacity: 0 !important;
  &:focus {
    outline-style: none!important;
    outline-width: 0px!important;
    outline-offset: 0px!important;
    @apply border-[1px] border-secondary-color-500;
  }
}

.toggle {
  &.toggle-primary {
    background-color: white!important;
    --tglbg: #22A8F0!important;
    border: 0px;
    &:not(:checked) {
      --tglbg: #CFCFCF!important;
    }
  }
}