@use './app/theme/input.theme.scss';
@use './app/theme/fonts.theme.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';

* {
  font-family: 'Poppins', sans-serif;
}

.toast-container {
  @apply toast;
}
.ngx-toastr {
  @apply alert;
  @apply w-fit hover:shadow-none #{!important};

  &.toast-success {
    @apply bg-[#E4F3D3] text-[#92D050] justify-start border-none rounded-2xl shadow-none;
    background-image: url("../src/assets/images/alert/alert-success.svg");
  }
  &.toast-info {
    @apply bg-[#D3EEFC] text-[#22A8F0] justify-start border-none rounded-2xl shadow-none;
    background-image: url("../src/assets/images/alert/alert-info.svg");
  }
  &.toast-warning {
    @apply bg-[#FFF5D0] text-[#FEAA2C] justify-start border-none rounded-2xl shadow-none;
    background-image: url("../src/assets/images/alert/alert-warning.svg");
  }
  &.toast-error {
    @apply bg-[#FFD4D0] text-[#E74C3C] justify-start border-none rounded-2xl shadow-none;
    background-image: url("../src/assets/images/alert/alert-error.svg");
  }
};

.radio {
  box-shadow: 0 0 0 4px #ffffff inset, 0 0 0 4px #fff inset !important;
  border-width: 1px;
}

.checkbox {
  @apply bg-white checked:bg-[url('../src/assets/checkbox-icon.svg')] bg-[length:90%_90%] bg-center rounded-md;
}

.modal {
  @apply
    backdrop-blur-sm;
  &::backdrop {
    @apply bg-transparent;
  }
}

.modal-box {
  @apply bg-white;
}

.btn {
  @apply
    rounded-full
    text-white;

  &.btn-outline {
    @apply 
      bg-white
      border-[1px];

    &.btn-outline-primary {
      @apply
        border-main-color-500
        text-main-color-500
        hover:bg-main-color-500
        hover:text-white;
    }
    &.btn-outline-secondary {
      @apply
      border-secondary-color-500
      text-secondary-color-500
      hover:bg-secondary-color-500
      hover:text-white;
    }
    &.btn-outline-neutral {
      @apply
      border-white-shades-400
      text-white-shades-400
      hover:bg-white-shades-400
      hover:text-white;
    }
    &.btn-outline-error {
      @apply
      border-error-color-500
      text-error-color-500
      hover:bg-error-color-500
      hover:text-white;
    }
  }
  &.btn-link {
    @apply
      font-medium
      no-underline
      hover:underline;
    
    &.btn-link-primary {
      @apply text-main-color-500 disabled:bg-transparent disabled:text-white-shades-400 text-base;
    }
    &.btn-link-secondary {
      @apply text-secondary-color-500 disabled:bg-transparent disabled:text-white-shades-400 text-base;
    }
    &.btn-link-neutral {
      @apply text-white-shades-500 disabled:bg-transparent disabled:text-white-shades-400 text-base;
    }
    &.btn-link-error {
      @apply text-error-color-500 disabled:bg-transparent disabled:text-white-shades-400 text-base;
    }
    &.btn-link-black {
      @apply text-black disabled:bg-transparent disabled:text-white-shades-400 text-base;
    }
    &.btn-link-white {
      @apply text-white disabled:bg-transparent disabled:text-white-shades-400 text-base;
    }
  }
}

@layer base {
  * {
    &:hover {
      scrollbar-color: inherit; 
    }
  }
  /* width */
  ::-webkit-scrollbar {
    @apply w-3 h-3;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-white-shades-200;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-white-shades-400 rounded-xl cursor-pointer;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-white-shades-400
  }
}

// this fixes Modal dialog adds a none existent scrollbar spacing to backdrop
html {
  scrollbar-gutter: auto !important;
}
// if scrollbar gutter crashes scroll use this
// html {
//   scrollbar-gutter: stable;
// }


/* Match Flatpickr's calendar background with DaisyUI's theme */
.flatpickr-calendar {
  background: hsl(var(--b1)); /* DaisyUI primary background color */
  color: hsl(var(--bc)); /* DaisyUI base content color */
  border: 1px solid hsl(var(--bc)); /* Match border color */
}

/* Style the day boxes */
.flatpickr-day {
  background: transparent;
  border-radius: 0.375rem; /* Tailwind-style rounded corners */
  color: hsl(var(--bc));
}

.flatpickr-day:hover {
  background: hsl(var(--b2)); /* Hover effect using DaisyUI secondary background */
}

.flatpickr-day.selected {
  background: hsl(var(--p)); /* Primary color for selected date */
  color: hsl(var(--pc)); /* Primary content color */
}

/* Style the navigation arrows */
.flatpickr-prev-month,
.flatpickr-next-month {
  color: hsl(var(--bc));
}

.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: hsl(var(--p));
}

